import React, { type FC } from "react";
import { type ISearchResultItemModel, type SearchResultTopicModel } from "~/feature/Search/generated-types";
import { Text as ChakraText, Heading, AspectRatio, Flex, Link as ChakraLink } from "@chakra-ui/react";
import { mediaApi } from "~/foundation/Jss";
import { imageSizes } from "../../_Base/Theme";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import SimpleImagePlaceholder from "../../Media/SimpleImagePlaceholder/SimpleImagePlaceholder";
import { fadeIn } from "../../_Base/Theme/styles/motions";
import { sizes } from "../../_Base/Theme/variables/sizes";

export enum AspectRatioOption {
	default = "default",
	cover = "cover",
	auto = "auto"
}
interface StoryListItemProps extends ISearchResultItemModel {
	gridColumn?: string;
	aspectRatio?: AspectRatioOption;
	isExternal?: boolean;
}

const StoryListItem: FC<StoryListItemProps> = ({
	url,
	smallImage,
	title,
	tags,
	description,
	gridColumn,
	aspectRatio,
	isExternal
}) => {

	// Get img aspect ratio
	const imageW = Number(smallImage?.width) || 4;
	const imageH = Number(smallImage?.height) || 3;

	let ratio = 4 / 3;
	if (aspectRatio === AspectRatioOption.cover) {
		ratio = 3 / 4;
	} else if (aspectRatio === AspectRatioOption.auto) {
		// calc aspect ratio from dimensions, fallback 4 / 3
		ratio = imageW / imageH;
	}

	const remToPx = (remValue: string, base: number = 16): number => {
		const numericValue = parseFloat(remValue.replace('rem', '').trim());
		return numericValue * base;
	};

	const contentWrapperWidthPx = remToPx(String(sizes.contentWrapperWidthXL));

	const getSrcSet = () => {
		if (aspectRatio === AspectRatioOption.cover) {
			return imageSizes.review.md;
		} else if (aspectRatio === AspectRatioOption.auto) {
			const baseWidth = contentWrapperWidthPx / 4;
			const baseHeight = baseWidth / ratio;

			return {
				cw: baseWidth,
				ch: baseHeight
			};
		}
		return imageSizes.articleList.sm;
	};

	const srcSet = getSrcSet();

	return (
		<MotionBox
			as="li"
			listStyleType="none"
			variants={fadeIn}
			gridColumn={gridColumn || "auto"}
		>
			<ChakraLink
				href={url}
				target={isExternal ? "_blank" : undefined}
				variant="unstyled"
				display="block"
				minW="100%"
				maxW="100%"
				_focus={{ outlineOffset: "0.5rem" }}
			>
				<AspectRatio ratio={ratio} h={aspectRatio === AspectRatioOption.auto ? "400px" : "auto"} overflow="hidden">
					{smallImage?.url ? (
						<LazyImage
							alt={smallImage.alt}
							src={mediaApi.updateImageUrl(smallImage?.url, srcSet)}
							w="100%"
							h="100%"
							sx={{
								objectFit: aspectRatio === AspectRatioOption.auto ? "contain !important" : "cover",
								objectPosition: aspectRatio === AspectRatioOption.auto ? "bottom" : "center center",
							}}
						/>
					) : (
						<SimpleImagePlaceholder missingAltText={title || "result"} />
					)}
				</AspectRatio>

				{title &&
					<Heading size="desktopHeadline5" my="3">{title}</Heading>
				}

				{tags && tags?.length > 0 && (
					<Flex flexWrap="wrap" gap={3}>
						{tags?.map((tagItem: SearchResultTopicModel, i) => (
							<ChakraText key={i} variant="tag">
								{tagItem.title}
							</ChakraText>
						))}
					</Flex>
				)}

				{description &&
					<ChakraText
						noOfLines={4}
						as="div"
						size="p16"
						dangerouslySetInnerHTML={{ __html: description }}
						mt="4"
					/>
				}
			</ChakraLink>
		</MotionBox>
	);
};

export default StoryListItem;