
export const Text = () => ({
	sizes: {
		desktopHeadline1Big: { //H1-XL
			fontFamily: 'heading',
			fontSize: "7rem",
			fontWeight: "500",
			lineHeight: ".9",
			letterSpacing: "-0.03em",
		},
		desktopHeadline1: { //H1-L
			fontFamily: 'heading',
			fontSize: "4rem",
			fontWeight: "500",
			lineHeight: "1.1",
			letterSpacing: "-0.03em",
		},
		mobileHeadline1: { //H1-L
			fontFamily: 'heading',
			fontSize: "3rem",
			fontWeight: "500",
			lineHeight: "1.2",
			letterSpacing: "-0.03em",
		},
		desktopHeadline2: { //H2-M
			fontFamily: 'heading',
			fontSize: "3rem",
			fontWeight: "500",
			lineHeight: "1.1",
			letterSpacing: "-0.03em",
		},
		mobileHeadline2: { //H2-M
			fontFamily: 'heading',
			fontSize: "2.5rem",
			fontWeight: "400",
			lineHeight: "1.1",
			letterSpacing: "-0.03em",
		},
		desktopHeadline3: { //H3-S
			fontFamily: 'heading',
			fontSize: "2.75rem",
			fontWeight: "400",
			lineHeight: "1.1",
			letterSpacing: "0",
		},
		mobileHeadline3: { //H3-Mobile
			fontFamily: 'heading',
			fontSize: "1.75rem",
			fontWeight: "400",
			lineHeight: "1",
			letterSpacing: "-0.03em",
		},
		desktopHeadline4: { //H4-XS
			fontFamily: 'heading',
			fontSize: "2.5rem",
			fontWeight: "400",
			lineHeight: "1",
			letterSpacing: "-0.03em",
		},
		mobileHeadline4: { //H3-Mobile
			fontFamily: 'heading',
			fontSize: "1.5rem",
			fontWeight: "400",
			lineHeight: "1.1",
			letterSpacing: "-0.03em",
		},
		desktopHeadline5: { //H5-XXS
			fontFamily: 'body',
			fontSize: "1.5rem",
			fontWeight: "600",
			lineHeight: "1.2",
		},
		mobileHeadline5: { //H5-XXS
			fontFamily: 'body',
			fontSize: "1.25rem",
			fontWeight: "600",
			lineHeight: "1.2",
		},
		desktopHeadline6: { //H6-XXXS
			fontFamily: 'body',
			fontSize: "1.125rem",
			fontWeight: "600",
			lineHeight: "1.2",
		},
		mobileHeadline6: { //H6-XXXS
			fontFamily: 'body',
			fontSize: "1.125rem",
			fontWeight: "600",
			lineHeight: "1.2",
		},
		desktopHeadline7: { //H7-XXXS
			fontFamily: 'body',
			fontSize: "1rem",
			fontWeight: "600",
			lineHeight: "1.2",
		},
		mobileHeadline7: { //H7-XXXS
			fontFamily: 'body',
			fontSize: "1rem",
			fontWeight: "600",
			lineHeight: "1.2",
		},
		p24: {
			fontFamily: 'body',
			fontSize: "1.5rem",
			fontWeight: "400",
			lineHeight: "1.4",
			letterSpacing: "0.01em",
		},
		p18: {
			fontFamily: 'body',
			fontSize: "1.125rem",
			fontWeight: "500",
			lineHeight: "1.4",
			letterSpacing: "0.01em",
		},
		p16: {
			fontFamily: 'body',
			fontSize: "1rem",
			fontWeight: "400",
			lineHeight: "1.5",
			letterSpacing: "0.01em",
		},
		ps: {
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "400",
		},
		small: {
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "400",
			lineHeight: "1.3",
		},
		desktopHero: {
			fontFamily: 'heading',
			fontSize: "4.375rem",
			fontWeight: "500",
			lineHeight: ".9",
			letterSpacing: "-0.131rem",
		},
		mobileHero: {
			fontFamily: 'heading',
			fontSize: "3rem",
			fontWeight: "500",
			lineHeight: "1.1",
			letterSpacing: "-0.090rem",
		}
	},
	variants: {
		//responsive styles for bodyTexts - imported in richtext styling
		//[p16, null, null, p18]
		bodyText: {
			fontFamily: 'body',
			fontSize: ["1rem", null, null, "1.125rem"],
			fontWeight: ["400", null, null, "500"],
			lineHeight: ["1.5", null, null, "1.4"],
			letterSpacing: "0.01em",
		},
		//responsive styles for smaller bodyTexts - imported in richtext styling for image-captions
		//[ps, null, null, p16]
		bodyTextSmall: {
			fontFamily: 'body',
			fontSize: [".75rem", null, null, "1rem"],
			fontWeight: "400",
			lineHeight: "1.5",
			letterSpacing: "0.01em",
		},
		navigationLinks: {
			//OBS this css chunk is imported in none chakra components such as NavLink, so do not use chakra theme variables with shortname
			fontFamily: "var(--chakra-fonts-body)",
			fontSize: ".875rem",
			fontWeight: "600",
			lineHeight: "normal",
			textUnderlineOffset: ".375rem",
			transition: "color .3s",
			_hover: {
				color: "var(--chakra-colors-AWPrimary-midBlue)"
			}
		},
		closeText: {
			fontFamily: 'body',
			fontSize: ".6976875rem",
			fontWeight: "500",
			lineHeight: "normal",
		},
		tag: {
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "600",
			lineHeight: "1.2",
			letterSpacing: "0",
			display: "inline-flex",
			h: "1.3125rem",
			alignItems: "center",
			bg: "rgba(21, 29, 43, 0.08)",
			border: "1px solid rgba(21, 29, 43, 0.20)",
			borderRadius: "1.25rem",
			px: "2",
		},
		defaultCategoryTag: {
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "600",
			lineHeight: "1.2",
			letterSpacing: "0",
			display: "inline-flex",
			h: "1.3125rem",
			alignItems: "center",
			bg: "rgba(21, 29, 43, 0.08)",
			border: "1px solid rgba(21, 29, 43, 0.20)",
			borderRadius: "1.25rem",
			px: "2",
		},
		defaultSubCategoryTag: {
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "400",
			lineHeight: "1.2",
			letterSpacing: "0",
			display: "inline-flex",
			h: "1.3125rem",
			alignItems: "center",
			backgroundColor: "rgba(21, 29, 43, 0.08)",
			border: "1px solid rgba(21, 29, 43, 0.08)",
			borderRadius: "1.25rem",
			px: "2",
		},
		defaultCategoryTagDark: {
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "600",
			lineHeight: "1.2",
			letterSpacing: "0",
			display: "inline-flex",
			h: "1.3125rem",
			alignItems: "center",
			backgroundColor: "rgba(255, 255, 255, 0.18)",
			border: "1px solid rgba(255, 255, 255, 0.20)",
			borderRadius: "1.25rem",
			px: "2",
			color: 'white'
		},
		defaultSubCategoryTagDark: {
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "400",
			lineHeight: "1.2",
			letterSpacing: "0",
			display: "inline-flex",
			h: "1.3125rem",
			alignItems: "center",
			backgroundColor: "rgba(255, 255, 255, 0.18)",
			border: "1px solid rgba(255, 255, 255, 0.18)",
			borderRadius: "1.25rem",
			px: "2",
			color: 'white'
		},
		cta: {
			//OBS this css chunk is imported in none chakra components such as NavLink, so do not use chakra theme variables with shortname
			fontFamily: "var(--chakra-fonts-body)",
			fontSize: ".9375rem",
			fontWeight: "600",
			lineHeight: "normal",
			letterSpacing: "-0.02em",
		},
		ctaSmall: {
			//OBS this css chunk is imported in none chakra components such as NavLink, so do not use chakra theme variables with shortname
			fontFamily: "var(--chakra-fonts-body)",
			fontSize: ".875rem",
			fontWeight: "600",
			lineHeight: "normal",
		},
		searchDesktop: {
			fontFamily: 'body',
			fontSize: "3.5rem",
			fontWeight: "600",
			lineHeight: "1.2",
			letterSpacing: "-0.03",
		},
		searchMobile: {
			fontFamily: 'body',
			fontSize: "2rem",
			fontWeight: "600",
			lineHeight: "1.2",
			letterSpacing: "-0.03",
		},
		headerLogoText: {
			color: "AWFunctional.white",
			fontFamily: "heading",
			fontSize: "1rem", // This + the logo size when header expanded is altered from design, responsively too large if not minified
			fontWeight: "700",
			lineHeight: "1.2",
			marginBottom: "-4px",
		},
		headerLogoTextHeroLG: {
			color: "AWPrimary.lightBlue",
			fontFamily: "heading",
			fontSize: "1.158rem",
			fontWeight: "400",
			lineHeight: "1.1",
			marginBottom: "-4px",
		},
		headerLogoTextHeroSM: {
			color: "AWPrimary.lightBlue",
			fontFamily: "heading",
			fontSize: "0.7443rem",
			fontWeight: "700",
			lineHeight: "1.1",
		},
		tagTransparent: {
			color: "AWFunctional.white",
			fontFamily: 'body',
			fontSize: ".75rem",
			fontWeight: "600",
			lineHeight: "1.2",
			letterSpacing: "0",
			display: "inline-flex",
			h: "1.3125rem",
			alignItems: "center",
			bg: "rgba(255, 255, 255, 0.18)",
			border: "1px solid rgba(255, 255, 255, 0.20)",
			borderRadius: "1.25rem",
			px: "2",
		},
		label: {
			fontFamily: "body",
			fontSize: ".9375rem",
			fontWeight: "600",
			lineHeight: "normal",
			letterSpacing: "-0.02em",
		}
	},
});
