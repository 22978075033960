import { keyFrames } from "./animations";

export const getGlobalStyling = {
	global: {
		...keyFrames().keyFrames,
		body: {
			fontFamily: 'body',
			color: 'AWPrimary.dark',
			bg: 'AWFunctional.white',

			'button': {
				'&:focus-visible': {
					outlineStyle: "solid",
					outlineWidth: "1px",
					outlineColor: "AWPrimary.midOrange",
					outlineOffset: "1px"
				},
			}
		},
		".page-wrapper": {
			display: "flex",
			flexDirection: "column",
			minH: "100vh",
			minW: "100%",
		},
		".container": {
			flexGrow: 1,
		},
		".nav__logo-main": {
			// w: ["8.59375rem", null, null, "10.7774375rem"],
			// h: ["1.265625rem", null, null, "1.5875rem"],
			"svg": {
				fill: "var(--chakra-colors-AWFunctional-white)",
				w: "100%",
				h: "100%",
			}
		},
		".searchFilters__radioGroup": {
			"input[type='radio']": {
				position: "relative",
				appearance: "none",
				width: "1.5625rem",
				height: "1.5625rem",
				borderRadius: "0.25rem",
				border: "1px solid",
				borderColor: "AWFunctional.darkGray",
				background: "transparent url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgOSIgd2lkdGg9IjEyIiBoZWlnaHQ9IjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMSA1LjM3NUwzLjcyNzI3IDhMMTEgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+') center no-repeat",
				backgroundColor: "AWFunctional.white",
				cursor: "pointer",
				"&:checked": {
					backgroundColor: "AWPrimary.dark",
					transition: "background-color 0.3s",
				},
			},
		},
		".lazyLoadList .LazyLoad": {
			position: "relative",

			"&:not(.is-visible)": {
				"&:after": {
					content: "''",
					position: "absolute",
					height: "100%",
					width: "100%",
					animationName: "skeleton",
					animationIterationCount: "infinite",
					animationTimingFunction: "linear",
					animationDuration: "0.5s",
					animationDirection: "alternate"
				}
			}
		},
		".lazyLoadList img[loading='lazy']": {
			opacity: 0,
			transition: "all .5s ease-in-out",

			"&.image-loaded": {
				opacity: 1
			},
		},
	}
};
