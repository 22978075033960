import { Text as ChakraText, ListItem, UnorderedList } from "@chakra-ui/react";
import { type FC, useContext } from "react";
import { type ItemReference } from "~/foundation/Jss";
import { type Tag } from "~/foundation/SitecoreContent/generated-types";
import { ColorThemeContext } from "../../Theme/ColorThemeContext";
import { type GlobalDictionary } from "../../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";
interface SimpleTag {
	id: string;
	title?: string;
}

interface TagListProps {
	tags: (ItemReference<Tag> | SimpleTag)[];
	variant?: string;
	backgroundColor?: string;
	borderColor?: string;
	textColor?: string;
	className?: string;
}

const TagList: FC<TagListProps> = ({
	tags,
	variant = "defaultCategoryTagDark",
	backgroundColor,
	borderColor,
	textColor,
	className
}) => {
	const [theme] = useContext(ColorThemeContext);

	// Set default styles if not provided
	const bg = backgroundColor || theme.ribbon.tagDefaultDarkBgColor;
	const border = borderColor || theme.ribbon.tagDefaultDarkBorderColor;
	const color = textColor || theme.ribbon.primaryColor;
	const [t] = useTranslation<GlobalDictionary>();

	if (!tags || tags.length === 0) return <></>;

	return (
		<UnorderedList
			listStyleType="none"
			padding={0}
			margin={0}
			display="flex"
			flexWrap="wrap"
			aria-label={t("general.search.tagedByLabel")}
		>
			{tags.map((tag) => {
				const tagTitle =
					"fields" in tag
						? tag.fields?.title?.value || tag.name
						: tag.title || "Untitled";

				return (
					<ListItem mr="2" mb="2" key={tag.id}>
						<ChakraText
							as="span"
							backgroundColor={bg}
							borderColor={border}
							variant={variant}
							color={color}
							className={className}
						>
							{tagTitle}
						</ChakraText>
					</ListItem>
				);
			})}
		</UnorderedList>
	);
};

export default TagList;
